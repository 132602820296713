import React, { Component } from "react";
import AuraButton from "./AuraButton";

class DashboardHub extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Dashboard HUB</span>
              {this.props.titleSuffix}
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <h5 style={{ fontWeight: "bold" }}>Visual Charts</h5>
                  <div className="contactform-field contactform-textarea">
                    To check on the results on the day of the end and overall
                    fundrasier:
                    <br />
                    <AuraButton label="Aura" link={this.props.links.aura} />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default DashboardHub;
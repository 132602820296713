import React, { Component } from "react";
import AuraButton from "./AuraButton";

class RegistrationHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkinChairs: [1, 2, 3, 4, 5, 6, 7, 8],
      helpDesks: [1, 2, 3, 4],
    };
  }

  componentDidMount() {}

  getCheckinDesks = () => {
    const { checkinChairs } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="form-validate">
        {checkinChairs.map((chairNumber) => (
          <div
            key={chairNumber}
            className="contactform-field contactform-textarea"
          >
            Checkin Desk {chairNumber}:
            <br />
            <AuraButton label={`Checkin Desk ${chairNumber}`} link={`${this.props.links.checkin}?chair_num=Checkin Desk ${chairNumber}`} />
          </div>
        ))}
      </form>
    );
  };

  getHelpDesks = () => {
    const { helpDesks } = this.state;

    return helpDesks.map((deskNumber) => (
      <div key={deskNumber} className="contactform-field contactform-textarea">
        Event Day Help Desk {deskNumber}:
        <br />
        <AuraButton label={`Help Desk ${deskNumber}`} link={`${this.props.links.register}?chair_num=Help Desk ${deskNumber}`} />
      </div>
    ));
  };

  render() {
    return (
      <>
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Registration HUB</span>
              {this.props.titleSuffix}
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  {this.getCheckinDesks()}

                  <div className="contactform-field contactform-textarea">
                    EDS for the bid card:
                    <br />
                    <AuraButton label="EDS" link={`${this.props.links.checkin}/dashboard`} />

                  </div>
                  {this.getHelpDesks()}
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default RegistrationHub;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/LandingPageComponent.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { default as EventDayHub } from "./EventDayHub";
import { default as TicketsHub } from "./TicketsHub";
import { default as WebsiteHub } from "./WebsiteHub";
import { default as RegistrationHub } from "./RegistrationHub";
import { default as DashboardHub } from "./DashboardHub";
import FontFaceObserver from "fontfaceobserver";

const menuItems = [
  {
    label: "Dashboard",
    link: "dashboard",
  },
  {
    label: "Tickets",
    link: "tickets",
  },
  {
    label: "Websites",
    link: "websites",
  },
  {
    label: "Registration",
    link: "registration",
  },
  {
    label: "Event Day",
    link: "event-day",
  },
];

const LandingPageComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isTest, setIsTest] = useState(
    window.location.hostname.includes("test.")
  );
  const [titleSuffix, setTitleSuffix] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [initialStart, setInitialStart] = useState(false);
  const [links, setLinks] = useState({});

  const [pageIndex, setPageIndex] = useState(() => {
    const savedPageIndex = localStorage.getItem("pageIndex");
    return savedPageIndex !== null ? parseInt(savedPageIndex, 10) : 0;
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");

    if (isTest) {
      setTitleSuffix(<> [Staff Only, Test]</>);
    } else {
      setTitleSuffix(<> [Staff Only]</>);
    }

    if (page) {
      const menuItemIndex = menuItems.findIndex((item) => item.link === page);

      if (menuItemIndex !== -1) {
        setPageIndex(menuItemIndex);
        params.delete("page");
        const newUrl = `${location.pathname}`;
        window.history.replaceState({}, "", newUrl);
      }
    } else {
      const savedPageIndex = localStorage.getItem("pageIndex");

      setPageIndex(savedPageIndex !== null ? parseInt(savedPageIndex, 10) : 0);
    }
  }, [location.search, navigate, location.pathname]);

  useEffect(() => {
    localStorage.setItem("pageIndex", pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setIsLoading(true);

    const fetchAttendees = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/aura/links`
        );
        const links = await response.json();
        setLinks(links);
      } catch (error) {
        console.error("Error fetching links:", error);
      }
    };

    fetchAttendees().then(() => {
      const font = new FontFaceObserver("Speedee Bold");

      font
        .load()
        .then(() => {
          console.log("Font has loaded!");
          setIsLoading(false);
          setInitialStart(true);
        })
        .catch(() => {
          console.error("Font failed to load.");
          setIsLoading(false);
        });
    });
  }, []);

  return (
    <>
      <LogoHeader
        selectedType={pageIndex}
        setSelectedType={setPageIndex}
        menuItems={menuItems}
      />
      <main className="add-pledges">
        {initialStart && (
          <>
            <section className="container stylization maincont">
              {!isLoading && (
                <>
                  {pageIndex === 0 && (
                    <DashboardHub
                      setIsLoading={setIsLoading}
                      toast={toast}
                      selectedType={pageIndex}
                      menuItems={menuItems}
                      links={links}
                      titleSuffix={titleSuffix}
                    />
                  )}

                  {pageIndex === 1 && (
                    <TicketsHub
                      setIsLoading={setIsLoading}
                      toast={toast}
                      selectedType={pageIndex}
                      menuItems={menuItems}
                      links={links}
                      titleSuffix={titleSuffix}
                    />
                  )}

                  {pageIndex === 2 && (
                    <WebsiteHub
                      setIsLoading={setIsLoading}
                      toast={toast}
                      selectedType={pageIndex}
                      menuItems={menuItems}
                      links={links}
                      titleSuffix={titleSuffix}
                    />
                  )}

                  {pageIndex === 3 && (
                    <RegistrationHub
                      setIsLoading={setIsLoading}
                      toast={toast}
                      selectedType={pageIndex}
                      menuItems={menuItems}
                      links={links}
                      titleSuffix={titleSuffix}
                    />
                  )}

                  {pageIndex === 4 && (
                    <EventDayHub
                      setIsLoading={setIsLoading}
                      toast={toast}
                      selectedType={pageIndex}
                      menuItems={menuItems}
                      links={links}
                      titleSuffix={titleSuffix}
                    />
                  )}
                </>
              )}
            </section>
          </>
        )}

        <ToastContainer />
        {isLoading && <Spinner />}
      </main>
    </>
  );
};

export default LandingPageComponent;
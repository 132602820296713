import "./css/App.css";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import LandingPageComponent from "./Soiree2024/LandingPageComponent";
import Auth from "./Auth";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<LandingPageComponent />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<LandingPageComponent />} />
      </Routes>
    </div>
  );
}

export default App;
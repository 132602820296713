import React, { Component } from "react";
import AuraButton from "./AuraButton";

class EventDayHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Event Day HUB</span>{this.props.titleSuffix}
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <h5 style={{ fontWeight: "bold" }}>POS: Terminal A</h5>
                  <div className="contactform-field contactform-textarea">
                    For activation stations: <br />
                    1. Raffle tickets <br />
                    2. Teddy Bear <br />
                    3. Bracelet
                    <br />
                    4. Wine Wall
                    <br />
                    <AuraButton label="POS: Terminal A" link={`${this.props.links.pos}/Terminal_A`} />
                  </div>
                  <h5 style={{ fontWeight: "bold" }}>POS: Terminal B</h5>
                  <div className="contactform-field contactform-textarea">
                    For activation stations: <br />
                    1. Raffle tickets <br />
                    2. Teddy Bear <br />
                    3. Bracelet
                    <br />
                    4. Wine Wall
                    <br />
                    <AuraButton label="POS: Terminal B" link={`${this.props.links.pos}/Terminal_B`} />
                  </div>
                  <h5 style={{ fontWeight: "bold" }}>Fund A Need</h5>
                  <div className="contactform-field contactform-textarea">
                  <AuraButton label="Fund A Need" link={this.props.links.pledges} />
                  </div>
                  
                  <h5 style={{ fontWeight: "bold" }}>Wine Wall</h5>
                  <div className="contactform-field contactform-textarea">
                    Displays who bought what cork <br />
                    <AuraButton label="Wine Wall" link={`${this.props.links["wine-wall"]}`} />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default EventDayHub;
import React, { Component } from "react";
import AuraButton from "./AuraButton";

class WebsiteHub extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Website HUB</span>
              {this.props.titleSuffix}
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <div className="contactform-field contactform-textarea">
                    Raffle Ticket Sales:
                    <br />
                    <AuraButton label="Raffle" link={this.props.links.raffle} />
                  </div>
                  <div className="contactform-field contactform-textarea">
                    Auction Access Site:
                    <br />
                    <AuraButton label="Auction Access" link={this.props.links.auctionAccess} />

                  </div>
                  <div className="contactform-field contactform-textarea">
                    Auction Site:
                    <br />
                    <AuraButton label="Auction" link="https://cbo.io/bidapp/main.php?slug=soiree2024bslm" />
                  </div>
                  <div className="contactform-field contactform-textarea">
                    Auction Landing Page In CLICKBID: <br />
                    <AuraButton label="CLICKBID" link="https://soiree2024bslm.cbo.io" />
                  </div>
                  <div className="contactform-field contactform-textarea">
                    Donation Page: <br />
                    <AuraButton label="Donate" link={`${this.props.links.donate}/soiree2024`} />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default WebsiteHub;
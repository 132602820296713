import React, { Component } from "react";
import { Button } from "react-bootstrap";

class AuraButton extends Component {
  handleClick = (e) => {
    e.preventDefault();
    window.open(this.props.link, "_blank");
  };

  render() {
    return (
      <div className="auth-submit">
        <div className="button-group">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className="custom-info-button submit-button"
            onClick={this.handleClick}
          >
            {this.props.label}
          </Button>
        </div>
      </div>
    );
  }
}

export default AuraButton;
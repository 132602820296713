import React, { Component } from "react";
import AuraButton from "./AuraButton";

class TicketsHub extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Tickets HUB</span>
              {this.props.titleSuffix}
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <h5 style={{ fontWeight: "bold" }}>Online Tickets</h5>
                  <div className="contactform-field contactform-textarea">
                    Earlybird Pricing: <br />
                    1. Single Tickets: $400 <br />
                    2. Table: $4,000 <br />
                    3. Corporate Table: $5,000
                    <br />
                    <br />
                    Regular Pricing: <br />
                    1. Single Tickets: $450 <br />
                    2. Table: $4,500 <br />
                    3. Corporate Table: $5,000 <br />
                    4. Ambassador Table: $10,000
                    <br />
                    <br />
                
                    <AuraButton label="Online Tickets" link={this.props.links.tickets} />
                  </div>
                  <h5 style={{ fontWeight: "bold" }}>Alternative Tickets</h5>
                  <div className="contactform-field contactform-textarea">
                    <AuraButton label="Alternative Tickets" link={this.props.links.alternative} />
                  </div>
                  <h5 style={{ fontWeight: "bold" }}>Young Professionals</h5>
                  <div className="contactform-field contactform-textarea">
                    Ticket Pricing:
                    <br />
                    1. Single Tickets: $300 <br />
                    <br />

                    <AuraButton label="Young Professionals" link={`${this.props.links.tickets}/exclusive`} />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default TicketsHub;